<template>
  <div class="p-10">
    <div
      class="mx-auto flex justify-between mb-5 p-5 border border-gray-200 shadow-md rounded-2xl bg-white"
    >
      <h3 class="text-rose text-3xl">{{ $t('location') }}</h3>
      <BackFa :text="$t('back')" />
    </div>
    <div class="rounded-2xl overflow-hidden shadow-md">
      <img
        v-if="isRtl"
        class="w-full"
        src="../assets/images/location.png"
        alt=""
      />
      <img
        v-else
        class="w-full"
        src="../assets/images/location-fr.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import BackFa from '../components/back/BackFa.vue'

export default {
  name: 'Location',
  components: { BackFa },
}
</script>

<style></style>
